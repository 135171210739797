import { useContext, useEffect } from "react";

import { NotificationContext } from "./NotificationContext";
import style from "./style.module.scss";

const NotificationContainer = () => {
  const { notification, removeNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (notification?.message) {
      setTimeout(removeNotification, notification.timeout);
    }
  }, [notification, removeNotification]);

  return (
    <div
      className={`${style.notification} ${style[notification.type]} ${
        notification?.message ? style.show : style.hide
      }`}
    >
      <span className={style.close} onClick={removeNotification}>
        x
      </span>
      <span className={style.message}>{notification.message}</span>
    </div>
  );
};

export default NotificationContainer;
